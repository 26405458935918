import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import htmlDecode from "html-entities-decoder"

import "./Stats.css";


function Stats(props) {
    const data = props.data;
    console.log(data)
    return (
        <div className="py-16 md:py-16 m-auto text-center" style={{ backgroundColor: "#f6faff" }}>
            <div className="px-6 md:px-0">
                <p className="section-name">{data.sectionName}</p>
                <h2 className="" >
                    {data.title}
                </h2>
                <p className="m-auto mt-4" style={{ maxWidth: "500px" }}>{data.description}</p>
            </div>

            {/* <div className="py-8 md:py-16 flex flex-wrap container m-auto text-left relative" >
                <div className="w-full md:w-1/2 bg-white mx-4 md:mx-0">
                    <div className=" pb-6 xl:pb-20 px-6 pt-6 md:px-12 md:pt-12">
                        <h3 className="" >
                            {data.componentGroup.title}
                        </h3>
                        <p className="m-auto mt-4">{data.componentGroup.description}</p>
                        <div>
                            {data.componentGroup.componentStats && data.componentGroup.componentStats.map((stat, i) => (
                                <div key={`stat-${i}`} class="relative pt-2">
                                    <div class="flex mb-2 items-center justify-between">
                                        <div>
                                            <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full" style={{ color: "#031b4e", fontSize: "16px" }}>
                                                {stat.title}
                                            </span>
                                        </div>
                                        <div class="text-right">
                                            <span class="font-semibold inline-block" style={{ color: "#031b4e", fontSize: "16px" }}>
                                                {stat.percentage}%
                                        </span>
                                        </div>
                                    </div>
                                    <div class="overflow-hidden h-2 mb-4 text-xs flex rounded" style={{ backgroundColor: "#f5f9fd" }}>
                                        <div style={{ width: `${stat.percentage}%`, backgroundColor: "#244989" }} class="stat-p shadow-none flex flex-col text-center whitespace-nowrap justify-center"></div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className="st-banner">
                        <h4>{ data.componentGroup.componentFooterText}</h4>
                    </div>
                </div>
                <div className="w-full md:w-1/2 relative stat-lin">
                    <div className="w-full st-cont">
                        <div className="relative st-hc">
                            <GatsbyImage
                                image={data.componentGroup.image.imageFile.childImageSharp.gatsbyImageData}
                                alt={data.componentGroup.image.altText}
                                className="m-auto" />
                        </div>
                    </div>
                </div>

            </div> */}
        </div>
    );
}

export default Stats;
