import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import "./AboutUs.css";


function AboutUs(props) {
    const data = props;

    return (
        <div className="about-container">
            <div className="container m-auto py-16 flex flex-wrap text-white">
                <div className="w-full lg:w-5/12 px-4">
                    <GatsbyImage
                        image={data.aboutImage.imageFile.childImageSharp.gatsbyImageData}
                        alt={data.aboutImage.altText}
                        className="m-auto" />
                    {/* <div className="ab-sp-offer px-8 py-4" style={{ backgroundColor: "#244989" }}>
                        <div>
                            {data.aboutImageSubtitle}
                        </div>
                        <div>
                            {data.aboutImageTitle}
                        </div>
                    </div> */}
                    <div className=" hidden lg:block">
                        {data.aboutImageGallery && data.aboutImageGallery.map((gal, i) => (
                            <GatsbyImage
                                image={gal.image.imageFile.childImageSharp.gatsbyImageData}
                                alt={gal.image.altText}
                                className="m-auto mt-8"
                                key={`gallery-img-${i}`}
                            />
                        ))}
                    </div>
                </div>
                <div className="w-full lg:w-7/12 text-left ab-left-tab px-4 mt-12 lg:mt-0">
                    <div className="section-name">
                        {data.sectionName}
                    </div>
                    <h2 className="text-4xl mb-6">
                        {data.title}
                    </h2>
                    <p className="mb-6" style={{ maxWidth: "610px" }}>
                        {data.paragraph}
                    </p>
                    <div style={{ maxWidth: "610px" }}>
                        {data.subParagraph && data.subParagraph.map((ab, i) => (
                            <div className="flex mb-4" key={`about-sp-${i}`}>
                                <div>
                                    <GatsbyImage
                                        image={ab.icon.imageFile.childImageSharp.gatsbyImageData}
                                        alt={ab.icon.altText} />
                                </div>
                                <div className="ab-editor pl-4" dangerouslySetInnerHTML={{ __html: ab.description }} />
                            </div>
                        ))}
                    </div>
                    {data.cta.label && (
                        <div className="mt-8">
                            <a href={data.cta.url} className="main-button-blue" >
                                {data.cta.label}
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AboutUs;